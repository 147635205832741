import {
  Dialog,
  DialogTitle,
  DialogContent,
  Link,
  Typography,
} from '@mui/material';

export default function Disclaimer(props) {
  const {open, onClose} = props;

  const handleCloseDisclaimer = () => {
    onClose(false);
  };

  return(
    <Dialog open={open} onClose={handleCloseDisclaimer}>
      <DialogTitle>
        <Typography component='h2' variant='h4' gutterBottom sx={{mb:2}}>
          Legal Disclaimer
        </Typography>

      </DialogTitle>
      <DialogContent>
        <Typography component='h3' variant='h5' gutterBottom sx={{mb:1}}>
          Trademarks
        </Typography>
        <Typography component='p' variant='p' gutterBottom sx={{mb:1}}>
          The WhatsApp name and logo are properties of their respective owners.
          Text Away is in no way endorsed by or affiliated with WhatsApp LLC.
        </Typography>
        <Typography component='p' variant='p' gutterBottom sx={{mb:3}}>
          <Link color='#000' underline='none' href="https://www.flaticon.com/free-icons/speech-bubble" title="whatsapp icons">
            Speech bubble icons created by Stockio - Flaticon.
          </Link>
        </Typography>

        <Typography component='h2' variant='h5' gutterBottom sx={{mb:1}}>
          Terms of Use, Liabilities and Warranties
        </Typography>
        <Typography component='p' variant='p' gutterBottom sx={{mb:1}}>
          The Text Away app is provided with the purpose of helping you and is intended to be used for personal use only.
        </Typography>
        <Typography component='p' variant='p' gutterBottom sx={{mb:1}}>
          This app is provided to you "as is" without warranties of any kind, either express or implied. I do not warrant that this website and any services provided through this site will be uninterrupted, or error-free.
        </Typography>
        <Typography component='p' variant='p' gutterBottom sx={{mb:3}}>
          I am not liable for any damages resulting from or related to your use of this website.
        </Typography>

        <Typography component='h2' variant='h5' gutterBottom sx={{mb:1}}>
          Privacy Policy
        </Typography>
        <Typography component='p' variant='p' gutterBottom sx={{mb:2}}>
          The tool provided in this app does not track or collect any of your personal data may it be identifiable or unidentifiable. Inputs such as numbers and text entered into this website is not stored and is only used to generate WhatsApp's URL API.
        </Typography>
        <Typography component='p' variant='p' gutterBottom sx={{mb:1, fontWeight:'600'}}>
          External tools used on this app that may collect your data:
        </Typography>
        <Typography component='p' variant='p' gutterBottom sx={{mb:1, fontWeight:'600'}}>
          Google Analytics
        </Typography>
        <Typography component='p' variant='p' gutterBottom sx={{mb:1}}>
          This website uses Google Analytics to track and record website traffic. Google Analytics uses cookies. The information about your use of this website is generated by the Google Analytics cookie which is then transmitted to a Google server. It is possible to opt out of this by disabling the use of cookies on your browser. You can learn more about Google's privacy policy using the following resource:
        </Typography>
        <Typography component='p' variant='p' gutterBottom sx={{mb:1}}>
          <Link underline='none' color='primary' href='https://policies.google.com/privacy?hl=en-US'>
            https://policies.google.com/privacy
          </Link>
        </Typography>
      </DialogContent>
    </Dialog>
  )
}
